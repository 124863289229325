#profileHeader{
    background-image: linear-gradient(#1665c1, #2886ee);
    text-align: center;
}

#profileName{
    font-weight: bold;
    font-size: 150%;
}

