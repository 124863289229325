#qwerty{
    width: 80%;
    height: 125px;
    position: absolute;
    margin-top: 130px;
    margin-left: 10%;
    transform: translate(0,-50%);
    text-align: center;

}
#bottomHalf{
    height: 500px;
    width: 90%;
    margin-top: 75px;
    margin-left: 5%;
}

.doctorAvatar{
    /*height: unset!important;*/
}