.Data {
    border-radius: 3px;
    border: 1px solid #e6e6e6;
    background-color: #fff;
    margin-bottom: 60px;
    margin-left : 20%;
    margin-right: 20%;
}
.Post-image-bg {
    background-color: #efefef;
}
.Post-image img {
    display: block;
    width: 100%;
}
.Post-caption {
    padding: 20px 20px;
}
.Post-caption strong {
    font-family: 'PT Sans', sans-serif;
    font-weight: bold;
}
