.button-row {
    text-align: center;
}

.layerContainer {
    position: relative;
    padding: 0;
    margin: auto;
    text-align: center;
}

.drawDiv {
    position: absolute; pointer-events: none; }
